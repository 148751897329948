import services from '@/http';
import * as types from './mutation-types';
import * as storage from '../storage';
import { userAuth } from '@/modules/auth';

export const ActionLoadSession = ({commit, dispatch }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const user    = await services.auth.loadSession()
      const scopes  = await services.profile.scopes()

      commit(types.SET_USER, user.data.data)
      commit(types.SET_USER_SCOPES, scopes.data.data)

      resolve()
    } catch (err) {
      dispatch('ActionSignOut')

      reject(err)
    }
  })
}

export const ActionSetRedirect = ({commit}, payload) => {
  commit(types.SET_REDIRECT, payload.redirect)
  commit(types.SET_REDIRECT_TO, payload.redirect_to)
  commit(types.SET_PARAMS, payload.params)
}

export const ActionSetValidateMFA = ({commit},payload) => {
  commit(types.SET_MFA_VALIDATE, payload.mfa_validate)
}

export const ActionSetToken = ({commit}, payload) => {
  commit(types.SET_TOKEN, payload.token)

  userAuth.setToken("app", payload);
}

export const ActionSetTokenUser = ({commit}, payload) => {
  commit(types.SET_TOKEN, payload.token)
  commit(types.SET_MFA,payload.mfa_ativo)
  commit(types.SET_MFA_METODO,payload.mfa_padrao)
  commit(types.SET_MFA_OUTROS_METODO,payload.outros_metodos_mfa)
  commit(types.SET_CONFIMAR_EMAIL,payload.confirmar_email)
  commit(types.SET_CONFIRMAR_CELULAR,payload.confirmar_celular)

  userAuth.setToken("user", payload);
  userAuth.setHeaderToken(payload);
}

export const ActionSignOut = ({commit}) => {
  commit(types.SET_USER, {})
  commit(types.DELETE_TOKEN)
  storage.deleteCookieToken()
  storage.deleteCookieDomainToken()
  userAuth.deleteToken()
  userAuth.clear()
}