export default {
    user: {},
    token: '',
    mfa_ativo:'',
    mfa_padrao: {},
    outros_metodos_mfa: [],
    confirmar_email: 0,
    confirmar_celular: 0,
    redirect : false,
    redirect_to:'',
    params:{},
    mfa_validate: false
}