<template>
  <div
    class="
      login
      is-flex
      is-flex-direction-column
      is-justify-content-center
      is-align-items-center
    "
  >
    <img alt="movida logo" src="@/assets/logo.svg" />
    <h1 class="is-size-2 has-text-weight-bold pb-5">{{ appTitle }}</h1>

    <div class="card">
      <div class="card-content">
        <div class="content">
          <p class="title is-4 is-spaced has-text-centered">
            Aguarde... Você será direcionado para o Login.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/styles/LoginView.css";

export default {
  data: () => ({
    appTitle: process.env.VUE_APP_TITLE,
  }),
  mounted() {
    window.location.href = `${process.env.VUE_APP_URL_START_SAML_JIRA}`;
  },
};
</script>