import * as types from './mutation-types';

export default {
  [types.SET_USER] (state, payload){
    state.user = payload
  },
  [types.SET_USER_SCOPES] (state, payload){
    state.user.scopes = payload
  },
  [types.SET_TOKEN] (state, payload){
    state.token = payload
  },
  [types.SET_MFA] (state, payload){
    state.mfa_ativo = payload
  },
  [types.SET_MFA_METODO] (state, payload){
    state.mfa_padrao = payload
  },
  [types.SET_MFA_OUTROS_METODO] (state, payload){
    state.outros_metodos_mfa = payload
  },
  [types.SET_CONFIMAR_EMAIL] (state, payload){
    state.confirmar_email = payload
  },
  [types.SET_CONFIRMAR_CELULAR] (state, payload){
    state.confirmar_celular = payload
  },
  [types.SET_MFA_VALIDATE] (state, payload){
    state.mfa_validate = payload
  },
  [types.DELETE_TOKEN] (state){
    state.token = "";
  },
  [types.SET_REDIRECT] (state,payload){
    state.redirect = payload;
  },
  [types.SET_REDIRECT_TO] (state,payload){
    state.redirect_to = payload;
  },
  [types.SET_PARAMS] (state,payload){
    state.params = payload;
  }
}