import store from '../store'
import { userAuth } from '@/modules/auth';

export default async (to, from, next) => {
  document.title = process.env.VUE_APP_TITLE

  if (to.matched.some((record) => record.meta.title)) {
    document.title = `${process.env.VUE_APP_TITLE} - ${to.meta.title}`
  }

  if (to.name == 'jira'){
    next()
    
    return true
  }

  if (to.name == 'forgot-password'){
    next()
    
    return true
  }

  if (to.name == 'reset-password'){
    next()
    
    return true
  }
    
  if (to.name !== 'login') {
    if (!userAuth.hasTokenCookie()){
      next({ name: 'login' })
    } 
    
    if (!userAuth.hasTokenState()) {
      await store.dispatch('auth/ActionSetToken', {
        token: userAuth.getToken(),
      })
    }

    if (!userAuth.isAuthenticated()) {
      await store.dispatch('auth/ActionLoadSession');
    }

    if (!_.isEmpty(to.meta.permission)) {
      if (_.includes(userAuth.getPermissions(), to.meta.permission)) {
        next()
      } else {
        next({ name: 'forbidden' })
      }
    }
  }
  next()
}
